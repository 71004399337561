import React from 'react'

const MainSection = ({ mainStyles, totalStake }) => {
    {/* overviewWrap 부분 주석 처리 하기 */}
    return (
        <section className={`${mainStyles.main}`} >
                <h2 className={`${mainStyles.tit}`}>
                    MMT Staking<br />                    
                    makes happy things on your assets
                </h2>
                <p className={`${mainStyles.exp}`}>If you have stake mmt, that will continue to bring you more mmt and<br />good luck even when you are doing other things.</p>
            <div className={`${mainStyles.psr}`} >
                <img src={require(`../../assert/img/img_mainvisual.png`)} alt="메인 이미지" />
                <div className={`${mainStyles.overviewWrap}`} 
                    style={{display:"none"}}
                >
                    <div className={`${mainStyles.tit}`} >Overview</div>
                    <div className={`${mainStyles.totalWrap}`} >
                        <div className={`${mainStyles.tit}`} >Total Stake</div>
                        <div className={`${mainStyles.cont}`} >{totalStake} MMT</div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default MainSection