import React from 'react'

const TxPopupShow = ({ stakingStyles, onClickPopupClose, onClickExplorer, nowModalStatus }) => {
    return (
        <div className={`${stakingStyles.popupWrap} ${stakingStyles.staking}`}>
            <div className={`${stakingStyles.popupDim}`} onClick={onClickPopupClose}></div>
            <div className={`${stakingStyles.popupContainer}`} >
                <img src={require(`../assert/img/ico_close_bl.png`)} className={`${stakingStyles.closeBtn}`} onClick={onClickPopupClose} />

                {nowModalStatus == "rejected" ?
                    <div>
                        <div>
                            <img src={require(`../assert/img/ico_rejected.png`)} alt="ico-rejected" />
                        </div>
                        <div className={`${stakingStyles.bold}`} >Transaction Rejected</div>
                        {/* <div className={`${stakingStyles.explorer}`} style={{ cursor: "pointer" }} onClick={onClickExplorer}>View on Explorer</div> */}
                    </div>
                    : nowModalStatus == "loading" ?
                        <div>
                            <div>
                                <img src={require(`../assert/img/loading.gif`)} alt="loading_gif" />
                            </div>
                            <div className={`${stakingStyles.bold}`}>Waiting for confirmation</div>
                            <div className={`${stakingStyles.normal}`}>Confirm this transaction in your wallet</div>
                        </div>
                        : nowModalStatus == "submit" ?
                            <div>
                                <div>
                                    <img src={require(`../assert/img/ico_confirmed.png`)} alt="ico_confirmed" />
                                </div>
                                <div className={`${stakingStyles.bold}`}>Transaction Submitted</div>
                                <div className={`${stakingStyles.explorer}`} style={{ cursor: "pointer" }} onClick={onClickExplorer}>View on Explorer</div>
                            </div>
                            : ""}
            </div>
        </div>
    )
}

export default TxPopupShow