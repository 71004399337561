import React from 'react'


const StakingRecord = ({ stakingStyles, RecordListComponent, onClickRecordTab, recordTab }) => {
    return (
        <section className={`${stakingStyles.record}`} >
            <p className={`${stakingStyles.tit}`} >Record</p>
            <div className={`${stakingStyles.recordTab}`} >
                <button type="button" className={`${stakingStyles.btnWithdraw} ${recordTab === "Withdraw" ? stakingStyles.on : ""}`} onClick={() => onClickRecordTab("Withdraw")}>WITHDRAW</button>
                <button type="button" className={`${stakingStyles.btnDeposit} ${recordTab === "Deposit" ? stakingStyles.on : ""}`} onClick={() => onClickRecordTab("Deposit")}>DEPOSIT</button>
                <button type="button" className={`${stakingStyles.btnReward} ${recordTab === "Withdraw Reward" ? stakingStyles.on : ""}`} onClick={() => onClickRecordTab("Withdraw Reward")}>WITHDRAW <br />REWARD</button>
            </div>
            <div className={`${stakingStyles.recordTable}`}  >
                <div className={`${stakingStyles.thead}`} >
                    <div className={`${stakingStyles.date}`} >{recordTab} Time</div>
                    <div className={`${stakingStyles.amount}`} >Amount</div>
                    <div className={`${stakingStyles.block}`}  >Block</div>
                    <div className={`${stakingStyles.hash}`} >Hash</div>
                </div>
                <div className={`${stakingStyles.tbodyWrap}`}>
                    <RecordListComponent />
                </div>
            </div>
        </section>
    )
}

export default StakingRecord