import { createAction, handleAction, handleActions } from 'redux-actions';
import produce from 'immer';

const initialState = {
    account: "",
    active: false,
    chainId: "0",
};

const SETCHAINID = "user/SETCHAINID";
export const setChainId = createAction(SETCHAINID, (input) => input);

const SETACCOUNT = "user/SETACCOUNT";
export const setAccount = createAction(SETACCOUNT, (input) => input);

const SETACTIVE = "user/SETACTIVE";
export const setActive = createAction(SETACTIVE, (input) => input);

const user = handleActions({
    [SETCHAINID]: (state, { payload: input }) =>
        produce(state, (draft) => {
            draft.chainId = input;
        }),
    [SETACCOUNT]: (state, { payload: input }) =>
        produce(state, (draft) => {
            draft.account = input;
        }),
    [SETACTIVE]: (state, { payload: input }) =>
        produce(state, (draft) => {
            draft.active = input;
        }),
}, initialState);

export default user;