import { BN } from "bn.js";

export const fromWei = (web3, amount) => {
    if (Number(amount) == 0) return 0
    return web3.utils.fromWei(amount);
}

export const toWei = (web3, amount) => {
    return web3.utils.toWei(amount);
}

export const toLocaleStringOption = (amount, num) => {
    if (amount == 0) {
        return 0
    } else if (amount.includes(".")) {
        const parts = amount.toString().split('.');
        let parts1 = parts[1].substr(0, num);
        let parts2 = parts[0] + "." + parts1;
        parts2 = (parts2 * 10000) / 10000;
        const parts3 = parts2.toString().split('.');
        parts3[0] = parts3[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return parts3.join('.');
    } else {
        amount = amount.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return amount;
    }
}

export const toThousandsStringOption = (amount) => {
    if (amount == 0) {
        return 0
    } else if (amount.includes(".")) {
        const parts = amount.toString().split('.');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return parts.join('.');
    } else {
        amount = amount.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return amount;
    }
}


export const aprFormula = async (web3, rewardYearAmount, poolToalAmount) => {
    if(`${poolToalAmount}` == "0" ) return 0;
    let divAmount = (rewardYearAmount / poolToalAmount)*100;
    return `${divAmount}`;
}