import { createAction, handleAction, handleActions } from 'redux-actions';
import produce from 'immer';

const initialState = {
    pid: "",
    nowUrl: "",
    stateInterval: "",
    totalStakeInterval: "",
    stakingPidInterval: "",
    withdrawLockInterval: "",
    aprInterval: "",
    stakingList: [],
    userInfo: [],
};

const SETPID = "staking/SETPID";
export const setPid = createAction(SETPID, (input) => input);

const SETSTATEINTERVAL = "staking/SETSTATEINTERVAL";
export const setStateInterval = createAction(SETSTATEINTERVAL, (input) => input);

const SETTOTALSTAKEINTERVAL = "staking/SETTOTALSTAKEINTERVAL";
export const setTotalStakeInterval = createAction(SETTOTALSTAKEINTERVAL, (input) => input);

const SETSTAKELISTINTERVAL = "staking/SETSTAKELISTINTERVAL";
export const setStakeListInterval = createAction(SETSTAKELISTINTERVAL, (input) => input);

const SETSTAKINGPIDINTERVAL = "staking/SETSTAKINGPIDINTERVAL";
export const setStakingPidInterval = createAction(SETSTAKINGPIDINTERVAL, (input) => input);

const SETWITHDRAWLOCKINTERVAL = "staking/SETWITHDRAWLOCKINTERVAL";
export const setWithdrawLockInterval = createAction(SETWITHDRAWLOCKINTERVAL, (input) => input);

const SETNOWURL = "staking/SETNOWURL";
export const setNowUrl = createAction(SETNOWURL, (input) => input);

const SETUSERINFO = "staking/USERINFO";
export const setUserInfo = createAction(SETUSERINFO, (input) => input);

const SETSTAKINGLIST = "staking/SETSTAKINGLIST";
export const setStakingList = createAction(SETSTAKINGLIST, (input) => input);


const staking = handleActions({
    [SETPID]: (state, { payload: input }) =>
        produce(state, (draft) => {
            draft.pid = input;
        }),
    [SETNOWURL]: (state, { payload: input }) =>
        produce(state, (draft) => {
            draft.nowUrl = input
        }),
    [SETSTATEINTERVAL]: (state, { payload: input }) =>
        produce(state, (draft) => {
            draft.stateInterval = input
        }),
    [SETTOTALSTAKEINTERVAL]: (state, { payload: input }) =>
        produce(state, (draft) => {
            draft.totalStakeInterval = input
        }),
    [SETSTAKELISTINTERVAL]: (state, { payload: input }) =>
        produce(state, (draft) => {
            draft.aprInterval = input
        }),
    [SETSTAKINGPIDINTERVAL]: (state, { payload: input }) =>
        produce(state, (draft) => {
            draft.stakingPidInterval = input
        }),
    [SETWITHDRAWLOCKINTERVAL]: (state, { payload: input }) =>
        produce(state, (draft) => {
            draft.withdrawLockInterval = input
        }),
    [SETSTAKINGLIST]: (state, { payload: input }) =>
        produce(state, (draft) => {
            draft.userInfo = input
        }),
    [SETUSERINFO]: (state, { payload: input }) =>
        produce(state, (draft) => {
            draft.stakingList = input
        }),
}, initialState);

export default staking;