import React from 'react'

const BenefitSection = ({mainStyles}) => {
    return (
        <section className={`${mainStyles.benefit}`} >
            <div className={`${mainStyles.wrap}`} >
                <p className={`${mainStyles.tit}`} >MMT Staking's benefits for you</p>
                <ul className={`${mainStyles.benefitList}`} >
                    <li>
                        <div className={`${mainStyles.circle}`} >
                            <img src={require(`../../assert/img/ico_benefit_01.png`)} alt="benefit01" />
                        </div>
                        <p className={`${mainStyles.cont}`} >Passive <br />income</p>
                    </li>
                    <li>
                        <div className={`${mainStyles.circle}`} >
                            <img src={require(`../../assert/img/ico_benefit_02.png`)} alt="benefit02" />
                        </div>
                        <p className={`${mainStyles.cont}`} >High <br />APR</p>
                    </li>
                    <li>
                        <div className={`${mainStyles.circle}`} >
                            <img src={require(`../../assert/img/ico_benefit_03.png`)} alt="benefit03" />
                        </div>
                        <p className={`${mainStyles.cont}`} >Stable <br />system</p>
                    </li>
                </ul>
            </div>
        </section>
    )
}

export default BenefitSection