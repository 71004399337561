import React, { useEffect, useState } from 'react';
import { Link, useLocation } from "react-router-dom";

import headerStyles from "../assert/css/Header.module.css";
import { fromWei, toLocaleStringOption } from '../common/common';

const Header = ({ transactionHash, mobileCheck, MMTBalance, getMMTBalance, web3, walletInfoDim, onClickWalletInfo, popUp, popUpType, onClickPopUpDim, onClickDisConnect, onClickConnectWalletBtn, onClickWalletConnect, onClickMetaMask, active, account, connectBtn }) => {

  const { pathname } = useLocation();

  const [reAccount, setReAccount] = useState('');
  const [headerActive, setHeaderActive] = useState(false);

  const accountReNumber = () => {
    if (account != undefined) {
      const startStr = account.substring(0, 3);
      const endStr = account.substring(account.length, account.length - 3);

      setReAccount(startStr + '...' + endStr);
    }
  };

  useEffect(() => {
    if (account != "") {
      accountReNumber();
    }
  }, [account]);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (document.documentElement.scrollTop == 0) {
        setHeaderActive(false);
      } else {
        setHeaderActive(true);

      }
    });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    if (web3 != undefined) {
      getMMTBalance();
    }
  }, [web3, account, active, transactionHash]);

  useEffect(() => {
    if (mobileCheck && walletInfoDim) {
      document.body.style.cssText = `
      overflow: hidden;
      height: 100%;
      min-height: 100%;
      touch-action: none
      `
    } else {
      document.body.style.cssText = ``
    }
  }, [mobileCheck, walletInfoDim])


  return (
    <div className={`${headerStyles.header} ${headerStyles.main_header} ${headerActive ? headerStyles.fixed : ""}`}>
      <div className={`${headerStyles.headerInner}`}>
        <div className={`${headerStyles.logo}`}>
          <Link to={"/"}>
            <img src={require(`../assert/img/logo@3x.png`)} width={"145"} alt="로고" />
          </Link>
        </div>
        <div className={`${headerStyles.psr}`}>
          {active ?
            <button type="button" id={headerStyles.btnWallet} className={`${headerStyles.btnConnect}`} onClick={onClickWalletInfo}>{reAccount}</button>
            :
            <button type="button" id={headerStyles.btnConnect} className={`${headerStyles.btnConnect}`} onClick={onClickConnectWalletBtn}>Connect wallet</button>
          }
          {walletInfoDim ?
            <div id={headerStyles.walletInfoPop} className={`${headerStyles.walletInfoPopup}`}>
              <div className={`${headerStyles.walletInfoPopupDim}`} onClick={onClickWalletInfo}></div>
              <div className={`${headerStyles.walletInfo}`} >
                <div className={`${headerStyles.title}`}  >
                  <span>My wallet</span>
                  <img src={require(`../assert/img/ico_close_bl.png`)} className={`${headerStyles.closeBtn}`} onClick={onClickWalletInfo} />
                </div>
                <div className={`${headerStyles.myAddress}`}  >
                  <div className={`${headerStyles.myAddressTitle}`}  >My address</div>
                  <div className={`${headerStyles.addressNumber}`}  >{account}</div>
                </div>
                <div className={`${headerStyles.myBalance}`}  >
                  <div className={`${headerStyles.myBalanceTitle}`}  >My balance</div>
                  <div className={`${headerStyles.balanceList}`}  >
                    <div className={`${headerStyles.balance}`}  >
                      <div>
                        <img src={require(`../assert/img/staking/sym_mmt@2x.png`)} width={"40"} alt="xrp" />
                        <span>MMT</span>
                        <span className={`${headerStyles.copied}`}  >copied</span>
                      </div>
                      <span>{toLocaleStringOption(MMTBalance, 4)}</span>
                    </div>
                  </div>
                </div>
                <button className={`${headerStyles.disconnectBtn}`} onClick={onClickDisConnect} >Disconnect</button>
              </div>
            </div>
            : ""}
        </div>
      </div>
      {popUp ?
        popUpType == "connectWallet" ?
          <div id={headerStyles.connectWalletPop} className={`${headerStyles.popupWrap}`} >
            <div className={`${headerStyles.popupDim}`} onClick={() => { onClickPopUpDim() }}></div>
            <div className={`${headerStyles.popupContainer}`} >
              <div className={`${headerStyles.title}`} >Connect Wallet</div>
              <div className={`${headerStyles.connectList}`} >
                <div className={`${headerStyles.mb}`} onClick={onClickMetaMask}>
                  <img src={require(`../assert/img/sym_metamask@2x.png`)} width={"40"} alt="metamask" />
                  MetaMask
                </div>
                <div onClick={onClickWalletConnect}>
                  <img src={require(`../assert/img/sym_walletconnect@2x.png`)} width={"40"} alt="walletconnect" />
                  WalletConnect
                </div>
              </div>
              {/* <button type={"button"} className="howToConnectBtn">Learn how to connect</button>
              <p>I will not connect the wallet</p> */}
            </div>
          </div>
          : popUpType == "coming" ?
            <div id={headerStyles.openSoonPop} className={`${headerStyles.popupWrap}`} >
              <div className={`${headerStyles.popupDim}`} onClick={() => { onClickPopUpDim() }}></div>
              <div className={`${headerStyles.popupContainer}`} >
                <button type="button" className={`${headerStyles.btnClose}`} onClick={() => { onClickPopUpDim() }}>close</button>
                <div className={`${headerStyles.bigTxt}`} >MMT Staking will be<br />open soon.</div>
                <div className={`${headerStyles.smallTxt}`} >Our team is delighted to invite you to join this MMT Staking !</div>
              </div>
            </div>
            : ""
        : ""}
    </div>
  )
}

export default Header