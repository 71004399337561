import { BN } from 'bn.js';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import stakingStyles from "../assert/css/Staking.module.css";
import mainStyles from "../assert/css/Main.module.css";
import { aprFormula, fromWei, toLocaleStringOption, toThousandsStringOption, toWei } from '../common/common';
import StakingInfo from '../containers/Staking/StakingInfo';
import StakingRecord from '../containers/Staking/StakingRecord';
import StakingWallet from '../containers/Staking/StakingWallet';
import TxPopupShow from '../containers/TxPopupShow';
import { setNowUrl, setStakingPidInterval, setStateInterval, setWithdrawLockInterval } from '../redux_modules/staking';

import MMTStakingJSON from "../abi/MMTStaking.json";

const Staking = ({ onClickDocument, transactionHash, setTransactionHash, contractAddress, defaultProv, active, account, contract, web3, MMTBalance, onClickConnectWalletBtn }) => {

  const dispatch = useDispatch();

  let { pid } = useParams();

  const stateInterval = useSelector((state) => state.staking.stateInterval);
  const nowUrl = useSelector((state) => state.staking.nowUrl);
  const stakingPidInterval = useSelector((state) => state.staking.stakingPidInterval);
  const withdrawLockInterval = useSelector((state) => state.staking.withdrawLockInterval)

  const [nowpid, setNowPid] = useState("");
  const [wrapType, setWrapType] = useState("mainWrap");
  const [recordTab, setRecordTab] = useState("Withdraw");
  const [unstakeTabType, setUnstakeTabType] = useState(true)

  const [allEventArray, setAllEventArray] = useState();
  // const [typeEvent, setTypeEvent] = useState(recordTab);
  const [typeEventArray, setTypeEventArray] = useState([]);

  const [maxBtnType, setMaxBtnType] = useState("");

  const [lockTime, setLockTime] = useState("");
  const [lockTimeType, setLockTimeType] = useState("");

  const [userInfo, setUserInfo] = useState({
    amount: 0,
    lockStatus: false,
    rewardDebt: 0,
  });

  const [poolInfo, setPoolInfo] = useState({
    poolName: "",

    startRewardBlock: 0,
    endRewardBlock: 0,

    rewardMMTperBlock: 0,
    stakedBalance: 0,

    totalRewardbalance: 0,
    lastRewardBlock: 0,
    accumlatedRewardsPerShare: 0,

    //apr
    rewardRate: 0,

  });



  const [inputValue, setInputValue] = useState("");
  const [btnStatus, setBtnStatus] = useState(false);

  const [reward, setReward] = useState("0");

  const [message, setMessage] = useState("");

  
  // StakingWallet btn 클릭
  const onClickNowBtn = (type) => {
    let text = type=="stakeWrap"?"STAKE NOW":type=="unStakeWrap"?"Withdraw":"";
    setMessage(text)
    setInputValue("");
    setWrapType(type)
  }

  // recordTab btn 클릭
  const onClickRecordTab = async (type) => {
    setRecordTab(type);
    document.querySelector(`.${stakingStyles.tbodyWrap}`).scrollTo(0, 0);
  }

  //  pid에 따른 userInfo, poolInfo 정보 가져오기
  const getInfo = async () => {

    // fromWei 하면서 넣기
    let userInfo = {
      amount: 0,
      lockStatus: false,
      rewardDebt: 0,
    }

    if (contract != undefined ) {
      if (account != "") {
        userInfo = await contract.methods.Stakers(pid, account).call();
        let lockStaus = await contract.methods.checkLockStatus(pid, account).call();
        userInfo = {
          amount: web3 != undefined ? fromWei(web3, userInfo.amount) : "0",
          lockStatus: lockStaus,
          rewardDebt: userInfo.rewardDebt,
        }
      }

      let pools = await contract.methods.pools(pid).call();

      let apr = await aprFormula(defaultProv, pools.totalRewardbalance, pools.stakedBalance);

      setPoolInfo({
        poolName: `${pools.poolName}`,

        startRewardBlock: `${pools.startRewardBlock}`,
        endRewardBlock: `${pools.endRewardBlock}`,

        rewardMMTperBlock: `${pools.rewardMMTperBlock}`,
        stakedBalance: fromWei(defaultProv, `${pools.stakedBalance}`),

        totalRewardbalance: `${pools.totalRewardbalance}`,
        lastRewardBlock: `${pools.lastRewardBlock}`,
        accumlatedRewardsPerShare: 0,

        minDepositAmount: fromWei(defaultProv, `${pools.minDepositAmount}`),

        //apr
        rewardRate: apr,
      });

      setUserInfo({
        amount: userInfo.amount,
        lockStatus: userInfo.lockStatus,
        rewardDebt: userInfo.rewardDebt,
      })

    }
  }

  // withdraw, deposit, withdrawReward 이벤트 호출
  const getAllEvent = async () => {
    let withdraw = [];
    let deposit = [];
    let withdrawReward = [];

    let withdrawData = [];
    let depositData = [];
    let withdrawRewardData = [];

    // 이벤트 함수 호출하여 적용
    if (active && web3 != undefined) {
      // withdraw = [1];
      // deposit = [];
      // withdrawReward = [1, 1, 1,]

      // 이벤트 호출
      withdraw = await contract.getPastEvents("Withdraw", { filter: { pid: pid, user: account }, fromBlock: 0, toBlock: "latest" });
      deposit = await contract.getPastEvents("Deposit", { filter: { pid: pid, user: account }, fromBlock: 0, toBlock: "latest" });
      withdrawReward = await contract.getPastEvents("WithdrawReward", { filter: { pid: pid, user: account }, fromBlock: 0, toBlock: "latest" });


      if (withdraw.length != 0) {
        for (let i = 0; i < withdraw.length; i++) {
          let data = {
            timeStamp: await timeStampToDate(withdraw[i].blockNumber),
            blockNumber: withdraw[i].blockNumber,
            amount: withdraw[i].returnValues.amount,
            transactionHash: withdraw[i].transactionHash,
          }
          withdrawData.push(data)
        }
      }

      if (deposit.length != 0) {
        for (let i = 0; i < deposit.length; i++) {
          let data = {
            timeStamp: await timeStampToDate(deposit[i].blockNumber),
            blockNumber: deposit[i].blockNumber,
            amount: deposit[i].returnValues.amount,
            transactionHash: deposit[i].transactionHash,
          }
          depositData.push(data)
        }
      }

      if (withdrawReward.length != 0) {
        for (let i = 0; i < withdrawReward.length; i++) {
          let data = {
            timeStamp: await timeStampToDate(withdrawReward[i].blockNumber),
            blockNumber: withdrawReward[i].blockNumber,
            amount: withdrawReward[i].returnValues.amount,
            transactionHash: withdrawReward[i].transactionHash,
          }
          withdrawRewardData.push(data)
        }
      }
    }

    let totalData = {
      withdrawData,
      depositData,
      withdrawRewardData
    }


    let data = {
      withdraw: withdrawData,
      deposit: depositData,
      withdrawReward: withdrawRewardData
    }

    setAllEventArray(data);
  }


  // withdraw, deposit, withdrawReward 버튼 클릭 시 호출
  const getTypeEvent = () => {
    let data = recordTab == "Withdraw" ? "withdraw" : recordTab == "Deposit" ? "deposit" : "withdrawReward";
    setTypeEventArray(allEventArray[`${data}`]);
  }


  // input
  const onChangeInputValue = (inputVal) => {
    let pattern = /^[0-9]*[.,]?[0-9]*$/;
    if (pattern.test(inputVal) == true) {
      let index = inputVal.indexOf(".");
      if (index != -1) {
        let decimalLength = inputVal.substr((index + 1), inputVal.length);
        if (decimalLength.length <= 18) {
          setInputValue(inputVal)
        }
      } else {
        setInputValue(inputVal)
      }
    }
  }

  // max button 
  const onClickMaxBtn = (type) => {
    setMaxBtnType(type);
    let balance = type == "mywallet" ? MMTBalance : type == "principal" ? userInfo.amount : reward //리워드 변수 넣기
    setInputValue(balance);
  }

  // reset button
  const onClickReset = () => {
    setInputValue("");
  }

  // 버튼 활성화 비활성화 기능
  const onChangeBtnStatus = async () => {
    if(message!="Staking..."&&message!="Withdrawing..."){
      // if (inputValue == "") {
      if (wrapType == "stakeWrap" && inputValue == "") {
        return setBtnStatus(false);
      }
      if (wrapType == "stakeWrap") {
        let nowBlockNumber = await web3.eth.getBlockNumber();
        if(nowBlockNumber <= poolInfo.endRewardBlock){
          if (new BN(`${toWei(web3, inputValue)}`).gt(new BN(`${toWei(web3, MMTBalance)}`))) {
            return setBtnStatus(false);
          } else if (new BN(`${toWei(web3, inputValue)}`).lte(new BN(`${toWei(web3, MMTBalance)}`))) {
            if (new BN(`${toWei(web3, inputValue)}`).gte(new BN(`${toWei(web3, poolInfo.minDepositAmount)}`))) {
              return setBtnStatus(true);
            } else {
              return setBtnStatus(false);
            }
          }
        }else if(nowBlockNumber > poolInfo.endRewardBlock){
          return setBtnStatus(false);
        }
  
      } else if (wrapType == "unStakeWrap") {
        let haveAmount = unstakeTabType ? userInfo.amount : reward; //리워드 변수 넣기
        if (unstakeTabType && userInfo.lockStatus == false) {
          return setBtnStatus(false);
        }
  
        if (haveAmount != 0) {
          return setBtnStatus(true);
        } else {
          return setBtnStatus(false);
        }
  
        // if (new BN(`${toWei(web3, inputValue)}`).gt(new BN(`${toWei(web3, haveAmount)}`))) {
        //   setBtnStatus(false);
        // } else if (new BN(`${toWei(web3, inputValue)}`).lte(new BN(`${toWei(web3, haveAmount)}`))) {
        //   setBtnStatus(true);
        // }
      }
    }

  }

  const onClickUnStakeTab = () => {
    setUnstakeTabType(!unstakeTabType);
  };

  // popup code - start
  const [popupShow, setPopupShow] = useState(false);
  const [nowModalStatus, setModalStatus] = useState("");

  const onClickBtn = async () => {
    setPopupShow(true);
    setModalStatus("loading");
    document.body.style.cssText = `
    overflow: hidden;
    height: 100%;
    min-height: 100%;
    touch-action: none
    `
    let result;

    let stakingContract = new web3.eth.Contract(MMTStakingJSON, contractAddress);
    let text = wrapType=="stakeWrap"?"Staking...":wrapType=="unStakeWrap"?"Withdrawing...":"";

    setMessage(text);
    setBtnStatus(false);

    try {

      if (wrapType == "stakeWrap") {
        // stake
        let value = toWei(web3, `${inputValue}`);
        result = await stakingContract.methods.deposit(`${pid}`).send({
          from: account,
          value: value
        })
      } else if (wrapType == "unStakeWrap") {
        if (unstakeTabType == true) {
          //Withdraw
          result = await stakingContract.methods.withdraw(`${pid}`).send({
            from: account,
          })

        } else if (unstakeTabType == false) {
          //WithdrawReward
          result = await stakingContract.methods.harvestRewards(`${pid}`).send({
            from: account,
          })

        }
      }

      if (result.status) {
        setModalStatus("submit")
        let text = wrapType=="stakeWrap"?"STAKE NOW":wrapType=="unStakeWrap"?"Withdraw":"";
        setMessage(text);
      } else {
      }
      setInputValue("");
      setTransactionHash(result.transactionHash)

    } catch (error) {
      if (error.code == 4001) {
        setPopupShow(false);
        let text = wrapType=="stakeWrap"?"STAKE NOW":wrapType=="unStakeWrap"?"Withdraw":"";
        setMessage(text);
      } else {
        console.log("error", error);
        setModalStatus("rejected");
        setTransactionHash(result.transactionHash);
        let text = wrapType=="stakeWrap"?"STAKE NOW":wrapType=="unStakeWrap"?"Withdraw":"";
        setMessage(text);
      }
    }
    // setModalStatus("loading")
  }
  // popup code - end


  // scan 사이트 연결
  const onClickScanSite = (tx) => {
    let url = `${process.env.REACT_APP_NETWORK_EXPLOERURL}/tx/${tx}`;
    window.open(url);
  }

  // timestamp 변환
  const timeStampToDate = async (blockNumber) => {
    try {
      let blockData = await web3.eth.getBlock(blockNumber);
      let date = moment.unix(blockData.timestamp).format("YY-MM-DD HH:mm:ss");
      return date;
    } catch (error) {
      console.log("error", error)
    }
  }


  const substrHash = (hash) => {
    if (hash != undefined) {
      const startStr = hash.substring(0, 6);
      const endStr = hash.substring(hash.length, hash.length - 6);

      return startStr + '...' + endStr;
    }
  };

  const onClickExplorer = () => {
    window.open(`${process.env.REACT_APP_NETWORK_EXPLOERURL}/tx/${transactionHash}`)
  }

  const onClickPopupClose = () => {
    setPopupShow(false);
  };

  const RecordListComponent = () => {

    return (Array.isArray(typeEventArray) && typeEventArray.length === 0 ?
      <div id ={stakingStyles.dataNone} className={`${stakingStyles.tbody} ${stakingStyles.tbodyTop}`}  >
        <div className={`${stakingStyles.dataNone}`}>
          <p className={`${stakingStyles.big}`} >No Data to Display</p>
          <p className={`${stakingStyles.small}`} >No transactions in your history yet.</p>
        </div>
      </div>
      : typeEventArray?.map((item, index) => (
        <div className={`${stakingStyles.tbody}  ${index == 0 ? stakingStyles.tbodyTop : ""}`} key={index}>
          <div className={`${stakingStyles.date}`} >{item.timeStamp}</div>
          <div className={`${stakingStyles.amount}`} >{toThousandsStringOption(`${fromWei(web3, item.amount)}`)} MMT</div>
          <div className={`${stakingStyles.block}`} >{toThousandsStringOption(`${item.blockNumber}`)}</div>
          <div className={`${stakingStyles.hash}`} style={{ cursor: "pointer" }} onClick={() => { onClickScanSite(item.transactionHash) }}>{substrHash(`${item.transactionHash}`)}</div>
        </div>
      )))
  }

  const setIntervalReward = async () => {
    let pendingReward = "0";
    if (account != "" && contract != undefined && web3 != undefined) {
      let stakingContract = new web3.eth.Contract(MMTStakingJSON, contractAddress);
      pendingReward = await stakingContract.methods.pendingRewards(pid, account).call();
      setReward(fromWei(web3, pendingReward));
    } else {
      setReward(fromWei(web3, pendingReward));
    }

  }

  const aprInterval = async () => {
    let pools = await contract.methods.pools(pid).call();

    let apr = await aprFormula(defaultProv, pools.totalRewardbalance, pools.stakedBalance);


    setPoolInfo({
      poolName: `${pools.poolName}`,

      startRewardBlock: `${pools.startRewardBlock}`,
      endRewardBlock: `${pools.endRewardBlock}`,

      rewardMMTperBlock: `${pools.rewardMMTperBlock}`,
      stakedBalance: fromWei(defaultProv, `${pools.stakedBalance}`),

      totalRewardbalance: `${pools.totalRewardbalance}`,
      lastRewardBlock: `${pools.lastRewardBlock}`,
      accumlatedRewardsPerShare: 0,

      minDepositAmount: fromWei(defaultProv, `${pools.minDepositAmount}`),

      //apr
      rewardRate: apr,
    });
  }

  const getLockTime = async () => {
    if (contract != undefined) {
      let owner = await contract.methods.owner().call();
      let lockBlock = await contract.methods.getLOCKED_BLOCK().call({
        from : owner
      });
      let lockBlockTime = new BN(`${lockBlock}`).mul(new BN(`${3}`)).toString();
      let lockTime ;
      if(lockBlockTime <= "60"){
        lockTime = new BN(`${lockBlockTime}`).div(new BN(`${60}`)).toString();
        setLockTimeType("minutes")
      }else if(lockBlockTime <= "3600") {
        lockTime = new BN(`${lockBlockTime}`).div(new BN(`${3600}`)).toString();
        setLockTimeType("hours")
      } else if(lockBlockTime <= "86400") {
        lockTime = new BN(`${lockBlockTime}`).div(new BN(`${86400}`)).toString();
        setLockTimeType("days")
      }
      setLockTime(lockTime)
    }
  }

  const withdrawLockStatusInterval = async () => {
    if(contract!=undefined && account!=""){
      let userInfo = await contract.methods.Stakers(pid, account).call();
      let lockStaus = await contract.methods.checkLockStatus(pid, account).call();
      userInfo = {
        amount: web3 != undefined ? fromWei(web3, userInfo.amount) : "0",
        lockStatus: lockStaus,
        rewardDebt: userInfo.rewardDebt,
      }
      setUserInfo(userInfo);
    }
  }

  useEffect(() => {
    if (contract != undefined) {
      getAllEvent();
    }
  }, [active, account, contract, web3, transactionHash])

  useEffect(() => {
    if (allEventArray != undefined) {
      getTypeEvent();
    }
  }, [recordTab, allEventArray]);

  useEffect(() => {
    getInfo();
  }, [web3, contract, account, transactionHash]);

  useEffect(() => {
    onChangeBtnStatus();
  }, [inputValue, web3, wrapType, transactionHash, poolInfo, userInfo, unstakeTabType, reward, message]);

  useEffect(() => {
    if (active == false) {
      if (wrapType != "mainWrap") {
        setWrapType("mainWrap")
      }
    }
  }, [active]);

  useEffect(() => {
    if (popupShow) {
      document.body.style.cssText = `
      overflow: hidden;
      height: 100%;
      min-height: 100%;
      touch-action: none
      `
    } else {
      document.body.style.cssText = ``
    }
  }, [popupShow]);



 // withdrawLock interval
  useEffect(() => {
    let letInterval = "";
    let nowUrls = window.location.href.split('/');
    let userInfo = {
      amount: 0,
      lockStatus: false,
      rewardDebt: 0,
    }
    if (account != "" && withdrawLockInterval == "" && contract != undefined && web3 != undefined) {

      letInterval = setInterval(() => {
        let url = window.location.href.split('/');
        withdrawLockStatusInterval();

        if (JSON.stringify(nowUrls) != JSON.stringify(url)) {
          clearInterval(letInterval);
          dispatch(setWithdrawLockInterval(""))
          setUserInfo(userInfo)
          // setReward("");
        }
      }, [5000]);

      dispatch(setWithdrawLockInterval(letInterval));

    } else if (account == "" && withdrawLockInterval != "") {
      clearInterval(withdrawLockInterval);
      dispatch(setWithdrawLockInterval(""));
      setUserInfo(userInfo)
      // setReward(userInfo);
    } else if (transactionHash != "") {
      withdrawLockStatusInterval();
    }
  }, [contract, web3, account, withdrawLockInterval, transactionHash]);

  // pid apr interval
  useEffect(() => {
    let letInterval = "";
    let nowUrls = window.location.href.split('/');
    if (stakingPidInterval == "" && contract != undefined) {

      letInterval = setInterval(() => {
        let url = window.location.href.split('/');
        aprInterval();
        if (JSON.stringify(nowUrls) != JSON.stringify(url)) {
          clearInterval(letInterval);
          dispatch(setStakingPidInterval(""))
        }
      }, [7000]);

      dispatch(setStakingPidInterval(letInterval));

    } else if (account == "" && stakingPidInterval != "") {
      // clearInterval(stakingPidInterval);
      // dispatch(setStakingPidInterval(""))
    } else if (transactionHash != "") {
      aprInterval();
    }
  }, [contract, web3, account, stakingPidInterval, transactionHash]);

  // pendingInterval
  useEffect(()=> {
    let letInterval = "";
    let nowUrls = window.location.href.split('/');
    if (account != "" && stateInterval == "" && contract != undefined && web3 != undefined) {
      setIntervalReward();

      letInterval = setInterval(() => {
        let url = window.location.href.split('/');
        setIntervalReward();

        if (JSON.stringify(nowUrls) != JSON.stringify(url)) {
          clearInterval(letInterval);
          dispatch(setStateInterval(""))
          setReward("");
        }
      }, [5000]);

      dispatch(setStateInterval(letInterval));

    } else if (account == "" && stateInterval != "") {
      clearInterval(stateInterval);
      dispatch(setStateInterval(""))
      setReward("");
    } else if (transactionHash != "") {
      setIntervalReward();
    }
  },[contract, web3, account, stateInterval, transactionHash])


  useEffect(() => {
    let url = window.location.href.split('/');
    if (nowUrl == "") {
      dispatch(setNowUrl(url));
    }
  }, []);

  useEffect(()=>{
    getLockTime()
  },[contract])



  return (
    <div className={`${stakingStyles.container}`}>
      <title>MMT Staking - {poolInfo.poolName}</title>
      <StakingInfo 
      stakingStyles={stakingStyles}
      poolInfo={poolInfo}
      onClickDocument={onClickDocument}
      />

      <StakingWallet
        active={active}
        stakingStyles={stakingStyles}
        btnStatus={btnStatus}
        unstakeTabType={unstakeTabType}
        onClickReset={onClickReset}
        MMTBalance={MMTBalance}
        wrapType={wrapType}
        userInfo={userInfo}
        poolInfo={poolInfo}
        reward={reward}
        inputValue={inputValue}
        message={message}
        onClickBtn={onClickBtn}
        onClickUnStakeTab={onClickUnStakeTab}
        onClickMaxBtn={onClickMaxBtn}
        onClickConnectWalletBtn={onClickConnectWalletBtn}
        onClickNowBtn={onClickNowBtn}
        onChangeInputValue={onChangeInputValue}
        lockTime={lockTime}
        lockTimeType={lockTimeType}
      />

      <StakingRecord
        stakingStyles={stakingStyles}
        RecordListComponent={RecordListComponent}
        onClickRecordTab={onClickRecordTab}
        recordTab={recordTab}
      />

      {/* popup 아래 코드 넣음 완료*/}
      {popupShow ? <TxPopupShow stakingStyles={stakingStyles} nowModalStatus={nowModalStatus} onClickExplorer={onClickExplorer} onClickPopupClose={onClickPopupClose} /> : ""}
    </div>
  )
}

export default Staking