import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';


import mainStyles from "../assert/css/Main.module.css"
import { aprFormula, fromWei, toLocaleStringOption, toThousandsStringOption } from '../common/common';
import { setPid, setStakeListInterval, setTotalStakeInterval, setUserInfo } from '../redux_modules/staking';
import MainSection from '../containers/Main/MainSection';
import BenefitSection from '../containers/Main/BenefitSection';
import StakingSection from '../containers/Main/StakingSection';
import { BN } from 'bn.js';

import MMTStakingJSON from "../abi/MMTStaking.json";
import moment from 'moment';


const Main = ({ preDocument, defaultProv, contract, active, onClickConnectWalletBtn, account, web3 }) => {

    const dispatch = useDispatch();

    const [totalStake, setTotalStake] = useState("0");
    const [stakingList, setStakingList] = useState([]);
    const [userInfo, setUserInfo] = useState([])


    const totalStakeInterval = useSelector((state)=>state.staking.totalStakeInterval);
    const stakeListInterval = useSelector((state)=>state.staking.aprInterval);

    // contract에 얼만큼 MMT가 들어가 있느냐
    const getTotalStake = async () => {
        let stakeTotalAmount = 0;
        let poolLength = await contract.methods.poolLength().call();
        for(let i = 0 ; i < poolLength ; i++){
            let pools = await contract.methods.pools(i).call();
            stakeTotalAmount = new BN(`${stakeTotalAmount}`).add(new BN(`${pools.stakedBalance}`)).toString(); 
        }
        stakeTotalAmount = toLocaleStringOption(fromWei(defaultProv, stakeTotalAmount), 4);
        setTotalStake(stakeTotalAmount);
    };

    // stakeList 가져오는거
    const getStakeList = async () => {
        // poolInfo, userInfo 가져오기
        let poolLength = await contract.methods.poolLength().call();
        // let poolLength = 3;
        let sendPoolData = [];
        let sendUserData = [];
        let poolInfo = {
            pid: 0,
            poolName: "",
            startRewardBlock: 0,
            endRewardBlock: 0,
            rewardMMTperBlock: 0,
            stakedBalance: 0,
            totalRewardbalance: 0,
            lastRewardBlock: 0,
            rewardRate: 0,
        };

        let userInfo = {
            amount: 0,
            rewardDebt: 0,
        };
        
        for (let i = 0; i < poolLength; i++) {
            let data = []
            
            if (active == true) {
                let staker = await contract.methods.Stakers(i,account).call();
                //userInfo 가져오기
                userInfo = {
                    amount: web3!=undefined?fromWei(web3, staker.amount):"0",
                    rewardDebt: staker.rewardDebt,
                };
            }
            //poolInfo 가져오기
            let pools = await contract.methods.pools(i).call();

            // 현재 블록
            let blockuNmber = await contract.methods.BlockNumber().call();
            // 현재 블록에 대한 타임 스탬프
            let {timestamp} = await defaultProv.eth.getBlock(blockuNmber);

            // startBlock
            let startBlockSubBlock = new BN(`${pools.startRewardBlock}`).sub(new BN(`${blockuNmber}`)).toString();
            let startBlockSubTimeStamp ;
            let startRewardTimeStamp ;

            
            if(Number(startBlockSubBlock) >= 0 ){
                startBlockSubTimeStamp = new BN(`${Math.abs(startBlockSubBlock)}`).mul(new BN(`${3}`)).toString();
                startRewardTimeStamp = new BN(`${timestamp}`).add(new BN(`${startBlockSubTimeStamp}`)).toString();
            }else{
                startBlockSubTimeStamp = new BN(`${Math.abs(startBlockSubBlock)}`).mul(new BN(`${3}`)).toString();
                startRewardTimeStamp = new BN(`${timestamp}`).sub(new BN(`${startBlockSubTimeStamp}`)).toString();
            }

            //  endBlock
            let endBlockSubBlock = new BN(`${pools.endRewardBlock}`).sub(new BN(`${pools.startRewardBlock}`)).toString();
            let endBlockSubTimeStamp;
            let endRewardTimeStamp;

            if(Number(endBlockSubBlock) >= 0 ){
                endBlockSubTimeStamp = new BN(`${Math.abs(endBlockSubBlock)}`).mul(new BN(`${3}`)).toString();
                endRewardTimeStamp = new BN(`${startRewardTimeStamp}`).add(new BN(`${endBlockSubTimeStamp}`)).toString();
            }else{
                endBlockSubTimeStamp = new BN(`${Math.abs(endBlockSubBlock)}`).mul(new BN(`${3}`)).toString();
                endRewardTimeStamp = new BN(`${startRewardTimeStamp}`).sub(new BN(`${endBlockSubTimeStamp}`)).toString();
            }

            let apr = await aprFormula(defaultProv, pools.totalRewardbalance, pools.stakedBalance);

            poolInfo = {
                pid: i,
                poolName: pools.poolName,
                startRewardBlock: pools.startRewardBlock,
                endRewardBlock:  pools.startRewardBlock,
                rewardMMTperBlock: pools.rewardMMTperBlock,
                stakedBalance: pools.stakedBalance,
                totalRewardbalance: pools.totalRewardbalance,
                lastRewardBlock: pools.lastRewardBlock,
                rewardRate: apr,
                commission : pools.commission,
                startRewardTimeStamp : getNewDate(startRewardTimeStamp),
                endRewardTimeStamp : getNewDate(endRewardTimeStamp),
            };

            sendPoolData.push(poolInfo);
            sendUserData.push(userInfo);
        }
        setUserInfo(sendUserData)
        setStakingList(sendPoolData);
    };

    const getNewDate = (timestamp) =>{
        let date = new Date(timestamp*1000);
        // let data = (date.getDate()<10?"0":"")+date.getDate()+"/"+(date.getMonth()<10?"0":"")+(date.getMonth()+1)+"/"+date.getFullYear()+" "+(date.getHours()<10?"0":"")+date.getHours()+":"+(date.getMinutes()<10?"0":"")+date.getMinutes()+":"+(date.getSeconds()<10?"0":"")+date.getSeconds()
        let data = moment(date).format("YY/MM/DD");

        return data
    }

    const setAprInterval = async () => {
        let poolLength = await contract.methods.poolLength().call();
        let sendPoolData = [];
        for (let i = 0; i < poolLength; i++) {
            let pools = await contract.methods.pools(i).call();

            // 현재 블록
            let blockuNmber = await contract.methods.BlockNumber().call();
            // 현재 블록에 대한 타임 스탬프
            let {timestamp} = await defaultProv.eth.getBlock(blockuNmber);

            // startBlock
            let startBlockSubBlock = new BN(`${pools.startRewardBlock}`).sub(new BN(`${blockuNmber}`)).toString();
            let startBlockSubTimeStamp ;
            let startRewardTimeStamp ;

            
            if(Number(startBlockSubBlock) >= 0 ){
                startBlockSubTimeStamp = new BN(`${Math.abs(startBlockSubBlock)}`).mul(new BN(`${3}`)).toString();
                startRewardTimeStamp = new BN(`${timestamp}`).add(new BN(`${startBlockSubTimeStamp}`)).toString();
            }else{
                startBlockSubTimeStamp = new BN(`${Math.abs(startBlockSubBlock)}`).mul(new BN(`${3}`)).toString();
                startRewardTimeStamp = new BN(`${timestamp}`).sub(new BN(`${startBlockSubTimeStamp}`)).toString();
            }

            //  endBlock
            let endBlockSubBlock = new BN(`${pools.endRewardBlock}`).sub(new BN(`${pools.startRewardBlock}`)).toString();
            let endBlockSubTimeStamp;
            let endRewardTimeStamp;

            if(Number(endBlockSubBlock) >= 0 ){
                endBlockSubTimeStamp = new BN(`${Math.abs(endBlockSubBlock)}`).mul(new BN(`${3}`)).toString();
                endRewardTimeStamp = new BN(`${startRewardTimeStamp}`).add(new BN(`${endBlockSubTimeStamp}`)).toString();
            }else{
                endBlockSubTimeStamp = new BN(`${Math.abs(endBlockSubBlock)}`).mul(new BN(`${3}`)).toString();
                endRewardTimeStamp = new BN(`${startRewardTimeStamp}`).sub(new BN(`${endBlockSubTimeStamp}`)).toString();
            }


            let apr = await aprFormula(defaultProv, pools.totalRewardbalance, pools.stakedBalance);

            let poolInfo = {
                pid: i,
                poolName: pools.poolName,
                startRewardBlock: pools.startRewardBlock,
                endRewardBlock:  pools.startRewardBlock,
                rewardMMTperBlock: pools.rewardMMTperBlock,
                stakedBalance: pools.stakedBalance,
                totalRewardbalance: pools.totalRewardbalance,
                lastRewardBlock: pools.lastRewardBlock,
                rewardRate: apr,
                commission : pools.commission,
                startRewardTimeStamp : getNewDate(startRewardTimeStamp),
                endRewardTimeStamp : getNewDate(endRewardTimeStamp),
            };
            sendPoolData.push(poolInfo);
        }

        setStakingList(sendPoolData);
    }

    useEffect(() => {
        if (contract != undefined) {
            getTotalStake();
        }
    }, [contract]);

    useEffect(() => {
        if (contract != undefined) {
            getStakeList();
        }
    }, [contract, active, web3, defaultProv]);

    useEffect(() => {
        // totalStakeInterval
        let letInterval = "";
        let nowUrls = window.location.href.split('/');
        if (totalStakeInterval == "" && contract != undefined) {
            getTotalStake();

            letInterval = setInterval(() => {
                let url = window.location.href.split('/');
                getTotalStake();

                if (JSON.stringify(nowUrls) != JSON.stringify(url)) {
                    clearInterval(letInterval);
                    dispatch(setTotalStakeInterval(""))
                    setTotalStake("");
                }
            }, [10000]);

            dispatch(setTotalStakeInterval(letInterval));

        } else if (account == "" && totalStakeInterval != "") {
            // clearInterval(totalStakeInterval);
            // dispatch(setTotalStakeInterval(""))
            // setTotalStake("");
        }
    }, [contract, web3, account, totalStakeInterval]);


    useEffect(() => {
        // stakeListInterval
        let letInterval = "";
        let nowUrls = window.location.href.split('/');
        if (stakeListInterval == "" && contract != undefined && stakingList.length != 0) {
            letInterval = setInterval(() => {
                let url = window.location.href.split('/');
                setAprInterval();

                if (JSON.stringify(nowUrls) != JSON.stringify(url)) {
                    clearInterval(letInterval);
                    dispatch(setStakeListInterval(""))
                    setStakingList("");
                }
            }, [10000]);

            dispatch(setStakeListInterval(letInterval));

        } else if (account == "" && stakeListInterval != "") {
            // clearInterval(stakeListInterval);
            // dispatch(setStakeListInterval(""))
            // setStakingList("");
        }
    }, [contract, stakeListInterval, stakingList]);


    const PoolListComponent = () => {
        return (stakingList=="coming soon" ?"":stakingList.map((item, index) => (
            <div className={`${mainStyles.tbody} ${mainStyles.flex}`} key={index}>
                <div className={`${mainStyles.name}`} >
                    <img src={require(`../assert/img/staking/sym_bnb@2x.png`)} width={"40"} alt="bnb" />
                    {/* <img src={`${item.poolName}.png`} width={"40"} alt={`${item.poolName}`} /> */}
                    <div>
                        <p>{item.poolName}</p>
                        <p className={`${mainStyles.startEnd}`}>Starts {item.startRewardTimeStamp}, Ends {item.endRewardTimeStamp}.</p>
                    </div>
                </div>
                <div className={`${mainStyles.reward}`} >{toLocaleStringOption(item.rewardRate,2)}%</div>
                <div className={`${mainStyles.mmt}`} >{toLocaleStringOption(userInfo[index].amount,4)} MMT</div>
                <div className={`${mainStyles.commission}`}>{item.commission}%</div>
                <div className={`${mainStyles.btn}`} >
                    {active ?
                        <Link to={`/staking/${item.pid}`}>
                            <button type="button" className={`${mainStyles.btnStaking}`} >
                                {/* <Link to={`/staking/${item.pid}`} onClick={()=>{onClickSetStaking(item)}}> */}
                                Staking
                            </button>
                        </Link>
                        :
                        <button type="button" className={`${mainStyles.btnStaking}`} onClick={onClickConnectWalletBtn}>
                            Connect Wallet
                        </button>
                    }
                </div>
            </div>
        )))
    }


    return (
        <div className={`${mainStyles.container}`}>
            <MainSection mainStyles={mainStyles} totalStake={totalStake} />
            <BenefitSection mainStyles={mainStyles}/>
            <StakingSection PoolListComponent={PoolListComponent} mainStyles={mainStyles} preDocument={preDocument}/>
        </div>
    )
}

export default Main