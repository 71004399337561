import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import Web3 from 'web3';
import WalletConnectProvider from "@walletconnect/web3-provider";
import appCss from "./App.css"

import Header from './containers/Header';
import Main from './pages/Main';

import { setAccount, setActive, setChainId } from './redux_modules/user';
import { fromWei, toLocaleStringOption } from './common/common';
import Footer from './containers/Footer';
import Staking from './pages/Staking';

import ERC20JSON from "./abi/ERC20.json";
import MMTStakingJSON from "./abi/MMTStaking.json";
import mainStyles from "./assert/css/Main.module.css"
import moment from 'moment';


const App = () => {

  const dispatch = useDispatch();

  const defaultChainId = process.env.REACT_APP_NETWORK_CHAINID;

  const account = useSelector((state) => state.user.account);
  const active = useSelector((state) => state.user.active);
  const chainId = useSelector((state) => state.user.chainId);

  const [connectBtn, setConnectBtn] = useState(false);
  const [web3, setWeb3] = useState();
  const [web3Provider, setWeb3Provider] = useState();
  const [walletConnectProvider, setWalletConnectProvider] = useState();
  const [totalStake, setTotalStake] = useState("0");
  const [contract, setContract] = useState();
  const [defaultProv , setDefaultProv] = useState();
  
  
  const [popUp, setPopUp] = useState(false);
  const [popUpType, setPopUpType] = useState("");
  const [walletInfoDim, setWalletInfoDim] = useState(false);

  const [MMTBalance, setMMTBalance] = useState("0");

  const [windowWidth, setWindowWidth] = useState("");
  const [mobileCheck, setMobileCheck] = useState(false);  // false이면 데탑;

  const [transactionHash, setTransactionHash] = useState("");

  const preDocument = useRef();

  const getMMTBalance = async () => {
    let haveAmount = 0;
    if(active){
      haveAmount = await web3.eth.getBalance(account);
    }
    setMMTBalance(fromWei(web3, haveAmount))
  }



  /*
    chainId 변동 감지 - start
  */

  if (window.ethereum != undefined) {
    window.ethereum.on("accountsChanged", async function (accountsed) {
      if (account != accountsed[0]) {
        onClickDisConnect();
      }
    });

    window.ethereum.on("chainChanged", async function (chainIds) {
      onClickDisConnect();
    });
  }

  if (walletConnectProvider != undefined) {
    walletConnectProvider.on("accountsChanged", async (accounts) => {
      if (account != "" && account != accounts[0]) {
        onClickDisConnect();
      }
    });

    walletConnectProvider.on("chainChanged", async (chainIds) => {
      if(chainIds!=chainId){
        onClickDisConnect();
      }
    });
  }

  /*
    chainId 변동 감지 - end
  */


  const onClickConnectWalletBtn = () => {
    setPopUp(true);
    // setPopUpType("connectWallet");
    setPopUpType("coming");
  }
  
  const onClickPopUpDim = () => {
    setPopUp(false);
  }

  const onClickWalletInfo = () => {
    setWalletInfoDim(!walletInfoDim);
  }

  const onClickMetaMask = async () => {
    if (window.ethereum != undefined) {
      try {
        const web3MetaMask = new Web3(window.ethereum);
        const accounts = await window.ethereum.send("eth_requestAccounts");
        const chainNum = await web3MetaMask.eth.getChainId();
        if (String(chainNum) == chainId) {
          setWeb3(web3MetaMask);
          setWeb3Provider(window.ethereum);
          dispatch(setAccount(accounts.result[0]));
          dispatch(setActive(true));
          let timestamp =  Math.floor(+ new Date() / 1000);
          let obj = {
            value : "inject",
            expire: timestamp+ 3600
          }
          let getObjString = window.localStorage.getItem("MammothStaking");
          if(!getObjString){
            const objString = JSON.stringify(obj)
            window.localStorage.setItem("MammothStaking", objString);
          }
          setPopUp(false);
        } else if (String(chainNum) != chainId) {
          onChangeChainId(window.ethereum)
        }
      } catch (error) {
        console.log("error",error);
      }
    }else{
      window.open(`https://metamask.app.link/dapp/${process.env.REACT_APP_URL}`);
    }
  };

  const onClickWalletConnect = async () => {
    try {
      let result = await walletConnectProvider.enable();
      if (result != undefined) {
        const requesetChainId = await walletConnectProvider.chainId;
        if (walletConnectProvider.connected && requesetChainId == chainId) {
          setWeb3(new Web3(walletConnectProvider));
          setWeb3Provider(walletConnectProvider);
          dispatch(setAccount(result[0]));
          dispatch(setActive(true));
          window.localStorage.removeItem("MammothStaking", "inject");
          setPopUp(false);
        } else if (requesetChainId != chainId) {
          onChangeChainId(walletConnectProvider);
        }
      }
    } catch (error) {
      setWalletConnectProvider();
    }
  };

  const onClickDisConnect = async () => {
    dispatch(setAccount(""));
    dispatch(setActive(false));
    setConnectBtn(false)
    window.localStorage.removeItem("MammothStaking");
    window.localStorage.removeItem("walletconnect");
    setWalletInfoDim(false);
  }

  const checkInject = async () => {
    let walletconnect = window.localStorage.getItem("walletconnect")
    walletconnect = JSON.parse(walletconnect);
    let objString = window.localStorage.getItem("MammothStaking");
    try {

      if(!objString){
        await onClickDisConnect();
      }
      if (objString) {
        const obj = JSON.parse(objString);
        let timestamp =  Math.floor(+ new Date() / 1000);
        if (timestamp > obj.expire) {
          window.localStorage.removeItem("MammothStaking");
        } else {
          await onClickMetaMask();
        }
      } else if (walletConnectProvider != undefined && walletconnect != undefined && walletconnect.connected) {
        await onClickWalletConnect(walletconnect.accounts);
      } else {

      }
    } catch (error) {

    }
  }

  const onChangeChainId = async (web3Provider) => {
    let netWorkVersion;
    let _rpcUrl;
    let blockExplorerURL;
    let chainName;
    let symbol;

    try {

      if (chainId == "88998") {
        // testnet
        netWorkVersion = "0x15ba6";
        _rpcUrl = "https://data-seed-premmt-1.mmtscan.io";
        blockExplorerURL = "https://testnet.mmtscan.io";
        chainName = "Mammoth TestNet";
        symbol = "MMT";
      } else if (chainId == "8898") {
        //  mainnet
        netWorkVersion = "0x22c2";
        _rpcUrl = "https://dataseed.mmtscan.io";
        blockExplorerURL = "https://mmtscan.io";
        chainName = "Mammoth Pro"
        symbol = "MMT";
      };

      await web3Provider.request({
        method: 'wallet_switchEthereumChain',
        params: [
          {
            chainId: netWorkVersion,
          },
        ],
      });

    } catch (error) {
      try {
        await web3Provider.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: netWorkVersion,
              chainName: chainName,
              rpcUrls: [_rpcUrl],
              nativeCurrency: {
                "name": chainName,
                "symbol": symbol,
                "decimals": 18
              },
              blockExplorerUrls: [blockExplorerURL]
            },
          ],
        });

      } catch (addError) {
      }
    }
  }


  const [contractAddress, setContractAddress] = useState("");

  useEffect(() => {
    if (defaultChainId == "88998") {
      dispatch(setChainId(defaultChainId));
      // setContractAddress("0xFAe37C7678651Af2F37231867b79791738Ff691d");
      // setContractAddress("0xc0eaBC46116a0b77716dCeb83449D7B267D39E79");
      // setContractAddress("0x53cD0a2faCD1a13f022705b2242f18430ed92008");
      setContractAddress("0xBD2a5172e3743D85D47b1a4A363E36Eb549fC07A");
      // setContractAddress("");
    } else if (defaultChainId == "8898") {
      setContractAddress("");
      dispatch(setChainId(defaultChainId));
    }
  }, []);

  // 컨트랙트 가져오기
  const defaultRpc = process.env.REACT_APP_NETWORK_RPC;


  const defaultProvider = () => {
    let web3 = new Web3(new Web3.providers.HttpProvider(defaultRpc));
    setDefaultProv(web3);
    return web3
  }

  const getContract = async () => {
    const web3 = defaultProvider()
    // let contract = new web3.eth.Contract(ERC20JSON, "0x6C744BaaC69ba137056c7c6757Ccb08B66D4c8b4");
    let contract = new web3.eth.Contract(MMTStakingJSON, contractAddress);
    setContract(contract)
  };

  const checkdetectMoileDevice = (agent, width) => {
    const mobileRegex = [
      /Android/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i
    ]

    // setMobileCheck(mobileRegex.some(mobile => agent.match(mobile)));
    setMobileCheck(width <= 720);
  };

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWindowWidth(window.innerWidth)
      checkdetectMoileDevice(window.navigator.userAgent, window.innerWidth);
    });

    return () => {
      window.removeEventListener("resize", () => {
        setWindowWidth(window.innerWidth)
        checkdetectMoileDevice(window.navigator.userAgent, window.innerWidth);
      });
    }
  }, []);

  // useEffect(() => {
  //   checkdetectMoileDevice(window.navigator.userAgent, window.innerWidth);
  // }, [])

  useEffect(()=>{
    if(contractAddress!=""){
      getContract();
    }
  },[contractAddress]);


  // const DefaultURL = process.env.REACT_APP_URL;

  // if (DefaultURL != window.location.hostname) {
  //   window.location.assign(`https://${DefaultURL}`);
  // }

  useEffect(() => {
    let providers;
    if (walletConnectProvider == undefined) {
      if (String(chainId) == "88998") {
        providers = new WalletConnectProvider({
          rpc: {
            "88998": "https://data-seed-premmt-1.mmtscan.io",
            "8898": "https://dataseed.mmtscan.io",
          },
          bridge: "https://bridge.walletconnect.org",
          qrcode: true
        });
      } else if (String(chainId) == "8898") {
        providers = new WalletConnectProvider({
          rpc: {
            "8898": "https://dataseed.mmtscan.io",
            "88998": "https://data-seed-premmt-1.mmtscan.io",
          },
          bridge: "https://bridge.walletconnect.org",
          qrcode: true
        });
      };

      setWalletConnectProvider(providers);
    }
  }, [chainId, walletConnectProvider]);

  useEffect(() => {
    checkInject();
  }, [
    chainId, walletConnectProvider
  ]);
  // 0x9CAe3a3a18dD0d72d7FB6e0E9DD6352F5d56F8F1

  const onClickDocument = async () => {
  }
  

  return (
    <BrowserRouter>
      <Header MMTBalance={MMTBalance} transactionHash={transactionHash} mobileCheck={mobileCheck} getMMTBalance={getMMTBalance} web3={web3} web3Provider={web3Provider} popUp={popUp} popUpType={popUpType} walletInfoDim={walletInfoDim} onClickWalletInfo={onClickWalletInfo} onClickPopUpDim={onClickPopUpDim} onClickDisConnect={onClickDisConnect} onClickConnectWalletBtn={onClickConnectWalletBtn} onClickWalletConnect={onClickWalletConnect} onClickMetaMask={onClickMetaMask} account={account} active={active} connectBtn={connectBtn} />
      <Routes>
        <Route path="/" element={<Main preDocument={preDocument} transactionHash={transactionHash} account={account} active={active} web3={web3} web3Provider={web3Provider}  defaultProv={defaultProv} contract={contract} onClickConnectWalletBtn={onClickConnectWalletBtn} />} />
        <Route path='/staking/:pid' element={<Staking onClickDocument={onClickDocument} transactionHash={transactionHash} setTransactionHash={setTransactionHash} contractAddress={contractAddress} defaultProv={defaultProv} active={active} MMTBalance={MMTBalance} account={account} web3={web3} web3Provider={web3Provider} contract={contract} onClickConnectWalletBtn={onClickConnectWalletBtn}/>} />
        {/* <Route path="*" element={<Main preDocument={preDocument} transactionHash={transactionHash} account={account} active={active} web3={web3} web3Provider={web3Provider}  defaultProv={defaultProv} contract={contract} onClickConnectWalletBtn={onClickConnectWalletBtn} />} /> */}
      </Routes>
      <Footer />
    </BrowserRouter>
  )
}

export default App;