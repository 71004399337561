import React from 'react';

const StakingSection = ({ preDocument, PoolListComponent, mainStyles }) => {
    // h3 부터 주석 처리

    return (
        <section className={`${mainStyles.staking}`} ref={preDocument}>
            {/* <h3 className={`${mainStyles.tit}`} >Staking</h3>
            <div className={`${mainStyles.stakingTable}`} >
                <div className={`${mainStyles.thead} ${mainStyles.flex}`} >
                    <div className={`${mainStyles.name}`} >Pool name</div>
                    <div className={`${mainStyles.reward}`} >Reward rate</div>
                    <div className={`${mainStyles.mmt}`} >My staked assets</div>
                    <div className={`${mainStyles.commission}`}>Commission</div>
                    <div className={`${mainStyles.btn}`} ></div>
                </div>
                <PoolListComponent />
            </div> */}
        </section>
    )
}

export default StakingSection