import React from 'react';
import footerStyles from "../assert/css/Footer.module.css"

const Footer = () => {
  return (
    <div className={`${footerStyles.copyright}`}  >
        <p className={`${footerStyles.copyright}`}  >Powered by Mammoth Chain</p>
        <p>MMTScan © 2022 (MMT-A)<br/><span>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</span>⛏ Built by the team mmtchain.io</p>
    </div>
  )
}

export default Footer