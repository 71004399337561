import React from 'react'
import { Link } from 'react-router-dom'
import { toLocaleStringOption, toThousandsStringOption } from '../../common/common'

const StakingInfo = ({ onClickDocument, stakingStyles, poolInfo }) => {
    return (
        <div className={`${stakingStyles.infoWrap}`} >
            <div className={`${stakingStyles.wrap}`}>
                <div className={`${stakingStyles.leftInfo}`} >
                    <div onClick={onClickDocument}>
                        <Link to={"/"} className={`${stakingStyles.backLink}`} >
                            Back to list
                        </Link>
                    </div>
                    <div>{poolInfo.poolName}</div>
                </div>
                <div className={`${stakingStyles.rightInfo}`} >
                    <div>
                        <span className={`${stakingStyles.tit}`} >Reward rate</span>
                        <span className={`${stakingStyles.reward}`}>{toThousandsStringOption(poolInfo.rewardRate)}%</span>
                    </div>
                    <div>
                        <span className={`${stakingStyles.tit}`} >Node staking status</span>
                        <span className={`${stakingStyles.mmt}`} >{toLocaleStringOption(poolInfo.stakedBalance,4)} MMT</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StakingInfo