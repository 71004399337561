import React from 'react'
import { toThousandsStringOption } from '../../common/common'

const StakingWallet = ({ lockTime, lockTimeType, message, reward, onClickUnStakeTab, wrapType, active, unstakeTabType, onClickBtn, onClickReset, poolInfo, userInfo, stakingStyles, btnStatus, MMTBalance, inputValue, onClickNowBtn, onClickConnectWalletBtn, onClickMaxBtn, onChangeInputValue }) => {
    return (
        <section className={`${stakingStyles.wallet}`} >
            <div className={`${stakingStyles.wrap}`}>
                <div className={`${stakingStyles.leftBox}`}>
                    <p className={`${stakingStyles.tit}`}>Wallet balance</p>
                    <div className={`${stakingStyles.mmtAmount}`}>
                        <img src={require(`../../assert/img/staking/ico_staking_detail_logo01@2x.png`)} width={"60"} alt="mmtLogo" />
                        <div>
                            <p className={`${stakingStyles.tit}`}>MMT Amount</p>
                            <p className={`${stakingStyles.cont}`}>{toThousandsStringOption(MMTBalance)} MMT</p>
                        </div>
                    </div>
                    <p className={`${stakingStyles.exp}`}>► By staking your MMT within MMTStaking, you will receive <span>rewards as per the reward rate.</span></p>
                </div>
                <div className={`${stakingStyles.rightBox}`}>
                    {wrapType == "mainWrap" ?
                        <div className={`${stakingStyles.mainWrap}`}>
                            <p className={`${stakingStyles.tit}`}>My staked assets</p>
                            <div className={`${stakingStyles.mmtBox}`}>{userInfo.amount} MMT</div>
                            <p className={`${stakingStyles.exp}`}>Stake your MMT from your wallet to receive rewards.</p>
                            {active ?
                                <div className={`${stakingStyles.btnWrap}`}>
                                    <button type="button" className={`${stakingStyles.btnStake}`} onClick={() => { onClickNowBtn("stakeWrap") }}>STAKE NOW</button>
                                    <button type="button" className={`${stakingStyles.btnUnstake}`} onClick={() => { onClickNowBtn("unStakeWrap") }}>UNSTAKE NOW</button>
                                </div>
                                :
                                <div className={`${stakingStyles.btnWrap}`}>
                                    <button type="button" className={`${stakingStyles.btnUnstake}`} onClick={() => { onClickConnectWalletBtn() }} style={{ backgroundColor: "#2288ff" }}>Connect Wallet</button>
                                </div>
                            }
                            <div className={`${stakingStyles.mmtInfoWrap}`}>
                                <div>
                                    <span className={`${stakingStyles.tit}`}>Rewards <br />earned to date</span>
                                    <span>{toThousandsStringOption(reward)} MMT</span>
                                </div>
                                {/* <div>
                                    <span className={`${stakingStyles.tit}`}>Withdrawal <br />in Progress</span>
                                    <span>0 MMT</span>
                                </div> */}
                            </div>
                        </div>

                        : wrapType == "stakeWrap" ?
                            <div id={stakingStyles.stakeWrap} className={`${stakingStyles.stakeWrap}`}>
                                <div className={`${stakingStyles.top}`}>
                                    <button type="button" className={`${stakingStyles.btnBack}`} onClick={() => { onClickNowBtn("mainWrap") }}>
                                        <img src={require(`../../assert/img/staking/ico_arrow_back@2x.png`)} width={"22"} alt="backIcon" />
                                    </button>
                                    <p className={`${stakingStyles.tit}`}>STAKING</p>
                                </div>
                                <div className={`${stakingStyles.mmtInfoWrap}`}>
                                    <div>
                                        <span className={`${stakingStyles.tit}`}>My Wallet Balance</span>
                                        <span>{toThousandsStringOption(MMTBalance)} MMT</span>
                                    </div>
                                </div>
                                <div className={`${stakingStyles.maxWrap}`}>
                                    <p className={`${stakingStyles.tit}`} onClick={() => { onClickMaxBtn("mywallet") }}>MAX</p>
                                    <div className={`${stakingStyles.iptWrap}`}>
                                        <input type="text" placeholder={`min ${poolInfo.minDepositAmount} MMT`} value={inputValue} onChange={(e) => { onChangeInputValue(e.target.value) }} />
                                        <button type="button" className={`${stakingStyles.btnDelete}`} onClick={onClickReset}>
                                            <img src={require(`../../assert/img/staking/ico_del@2x.png`)} width="20" alt="trashIcon" />
                                        </button>
                                    </div>
                                </div>
                                <button type="button" className={`${stakingStyles.btnNext} ${btnStatus ? stakingStyles.useAble : ""}`} disabled={!btnStatus} onClick={() => { onClickBtn() }}>{message}</button>
                                <p className={`${stakingStyles.exp}`}>Stake your MMT from your wallet to receive rewards.</p>
                            </div>
                            :
                            <div id={stakingStyles.unStakeWrap} className={`${stakingStyles.stakeWrap} ${stakingStyles.un}`} >
                                <div className={`${stakingStyles.top}`} >
                                    <button type="button" className={`${stakingStyles.btnBack}`} onClick={() => { onClickNowBtn("mainWrap") }}>
                                        <img src={require(`../../assert/img/staking/ico_arrow_back@2x.png`)} width="22" alt="backIcon" />
                                    </button>
                                    <p className={`${stakingStyles.tit}`}>UNSTAKE</p>
                                </div>
                                <div className={`${stakingStyles.unstakeTab}`}>
                                    <button type="button" className={`${unstakeTabType ? stakingStyles.on : ""}`} onClick={onClickUnStakeTab}>Principal</button>
                                    <button type="button" className={`${unstakeTabType ? "" : stakingStyles.on}`} onClick={onClickUnStakeTab}>Rewards</button>
                                </div>

                                <div className={`${stakingStyles.mmtInfoWrap}`}>
                                    {unstakeTabType ?
                                        <div>
                                            <span className={`${stakingStyles.tit}`} >My Staked Assets</span>
                                            <span>{toThousandsStringOption(userInfo.amount)} MMT</span>
                                        </div>
                                        :
                                        <div>
                                            <span className={`${stakingStyles.tit}`} >Rewards earned to date</span>
                                            <span>{toThousandsStringOption(reward)} MMT</span>
                                        </div>
                                    }
                                </div>
                                {/* <div className={`${stakingStyles.maxWrap}`} >
                                    <p className={`${stakingStyles.tit}`} >MAX</p>
                                    <div className={`${stakingStyles.iptWrap}`} >
                                        <input type="text" placeholder='0' value={inputValue} onChange={(e) => { onChangeInputValue(e.target.value) }} />
                                        <button type="button" className={`${stakingStyles.btnDelete}`} onClick={onClickReset}>
                                            <img src={require(`../../assert/img/staking/ico_del@2x.png`)} width="20" alt="trashIcon" />
                                        </button>
                                    </div>
                                </div> */}
                                <button type="button" className={`${stakingStyles.btnNext} ${btnStatus ? stakingStyles.useAble : ""}`} disabled={!btnStatus} onClick={() => { onClickBtn() }}>{message}</button>

                                <p className={`${stakingStyles.exp}`}>
                                    {/* * The extraction quantity should not be less than 1mmt<br /> */}
                                    {/* * Withdrawal of the principal will lock the position for 7 days, after 3 days to the account (this 3 days no earnings)<br /> */}
                                    * Withdrawal of the principal will lock the position for {lockTime} {lockTimeType}<br />
                                    {/* * After 7 days, please click the receive button on the wallet page to receive your principal */}
                                </p>
                            </div>
                    }
                </div>
            </div>
        </section>
    )
}

export default StakingWallet